
import React from "react";
const DeleteButton = (song) => {
  async function deleteSong(s, evt) {
    evt.preventDefault();
    // var response;
    var tmpsong = {
      Artist: s.song.Artist,
      Title: s.song.Title,
      Message: s.song.Message,
      ID: s.song.ID,
      SessionID: s.song.SessionID
    };
    console.log(tmpsong);
    const api_base_url = process.env.REACT_APP_LJ_SERVICE_BASE_URL;
    var deleteUrl;
    if (api_base_url.includes("localhost")) {
      deleteUrl = `${api_base_url}/delete`
    } else {
      deleteUrl = `${api_base_url}/session/delete`
    }
    const response = await fetch(deleteUrl, { method: "DELETE", body: JSON.stringify(tmpsong) });
    const responseJson = await response.json();
    // .then(response => response.json());
    console.log(responseJson);
    s.reloadFunction();

  }
  // float: right;
  // background-color: #132261;
  // color: white;
  // padding: 10px;
  // border: none;
  // border-radius: 5px;
  // /* margin-left: 20px; */
  // font-size: 20px;
  // cursor: pointer;
  return (

    <button onClick={(e) => deleteSong(song, e)} className="bg-slate-800 float-right px-2 py-2 text-3xl rounded-lg">❌</button>
  );
}

export default DeleteButton;