import React from "react";
const RequesterName = (props) => {
    const prompt = () => {
        if (props.requesterName) {
            return "From: " + props.requesterName;
        } else {
            return "";
        }
    };

    return (
        <div className="text-md font-bold">
           {prompt()}
        </div>
    );
}
export default RequesterName;


