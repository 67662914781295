import './App.css';
import RequestedSongs from './RequestedSongs';
import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import StatsPage from './StatsPage';

function App() {
  const [refreshNeeded, setRefreshNeeded] = React.useState(false);

  const resetSession = async () => {
    const api_base_url = process.env.REACT_APP_LJ_SERVICE_BASE_URL
    var resetUrl;
    if (api_base_url.includes("localhost")) {
      resetUrl = `${api_base_url}/QcUM39Wi0W876Hi/panicmode`
    } else {
      resetUrl = `${api_base_url}/session/QcUM39Wi0W876Hi/panicmode`
    }
    const response = await fetch(resetUrl, { method: "POST" });
    const responseJson = await response.json();
    setRefreshNeeded(!refreshNeeded);
    console.log(responseJson);

  }

  return (
    <BrowserRouter>
      <div className="text-center">
        <header className="bg-slate-800 text-white">
          <div className="py-2 px-2 flex flex-row place-items-center justify-between">
            <div>
              <nav>
                <Link to="/" className="mr-4">Home</Link>
                <Link to="/stats-page">Stats Page</Link>
              </nav>
            </div>
            <div className="text-xl"><button onClick={resetSession}>♻️</button></div>
          </div>
        </header>

        <Routes>
          <Route path="/" element={
            <div className="text-left">
              <RequestedSongs refreshNeeded={refreshNeeded} />
            </div>
          } />
          <Route path="/stats-page" element={<StatsPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
