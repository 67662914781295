import React from "react";
const SubscriberIndicator = (props) => {
    const isSubscriber = () => {
        if (props.subscriber) {
            return <div className="text-lg">⭐</div>;
        } else {
            return <div className="text-lg">&nbsp;</div>;
        }
    }
    return (
        <div className="text-center">
            {isSubscriber()}
        </div>
    );
}
export default SubscriberIndicator;


