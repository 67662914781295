import React, { useState, useEffect } from 'react';

function StatsPage() {
  const [stats, setStats] = useState([]);
  const api_base_url = process.env.REACT_APP_LJ_SERVICE_BASE_URL;
  var statsUrl;
  if (api_base_url.includes("localhost")) {
    statsUrl = `${api_base_url}/statistics`
  }
  else {
    statsUrl = `${api_base_url}/session/statistics`
  }
  useEffect(() => {
    fetch(statsUrl)
      .then(response => response.json())
      .then(data => setStats(data))
      .catch(error => console.error('Error fetching stats:', error));
  }, [statsUrl]);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Song Request Statistics (All Time)</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-collapse">
          <thead className="bg-gray-100">
            <tr>
              <th className="border px-4 py-2">Artist</th>
              <th className="border px-4 py-2">Title</th>
              <th className="border px-4 py-2">Request Count</th>
            </tr>
          </thead>
          <tbody>
            {stats.map((song, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="border px-4 py-2">{song.Artist}</td>
                <td className="border px-4 py-2">{song.Title}</td>
                <td className="border px-4 py-2 text-center">{song.RequestCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default StatsPage;